import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';
import UniversalStorage from '@vue-storefront/core/store/lib/storage';
import * as localForage from 'localforage';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

import {
  doPayment,
  DoPaymentQuery,
  checkOrderStatus,
  CheckOrderStatusMutation,
  zentoPaymentMethods,
  ZentoPaymentMethodsMutationVariables,
} from './operations.graphql';

const LastOrderStorageKey = 'lastOrder';

@Store
export class CheckoutPaymentStore extends BaseStore {
  /**
   * Online payment keeper
   */
  @Field
  private paymentData: DoPaymentQuery['paymentForm'];

  /**
   * Online payment status keeper
   */
  @Field
  private orderStatus: CheckOrderStatusMutation['orderStatus'];

  /**
   * Available payment methods status keeper
   */
  @Field
  private availablePayments: ZentoPaymentMethodsMutationVariables['input'];

  /**
   * Determines generate invoice value
   */
  @Field
  private generateInvoiceValue: boolean;

  /**
   * Determines generate invoice value
   */
  @Field
  private lastOrder: any; // TODO: add type

  @Field
  private loading: boolean;

  private storage!: UniversalStorage;

  /**
   * Retrive data for online payment
   */
  public async doPayment(orderId: string) {
    const storeUrl = this.context.config.zento.theme.storeData.storeUrl;
    let successUrl = storeUrl + '/order-confirmation?order=success';
    let errorUrl = storeUrl + '/order-confirmation?order=error';
    let payment: DoPaymentQuery = { paymentForm: {} };

    if (this.context.config.storeViews.multistore) {
      successUrl = storeUrl + '/' + currentStoreView().storeCode + '/order-confirmation?order=success';
      errorUrl = storeUrl + '/' + currentStoreView().storeCode + '/order-confirmation?order=error';
    }

    try {
      payment = await this.dataSources.graphQl.queue({
        ...doPayment,
        params: { orderId, successUrl, errorUrl },
      });
    } catch (e) {
      console.error('CheckoutPayment Store (doPayment): ', e);
    }

    const res = {
      order_id: payment.paymentForm.order_id,
      form_data: JSON.parse(payment.paymentForm.form_data),
    };

    this.paymentData = res;

    return this.paymentData;
  }

  /**
   * Check online payment status
   */
  public async checkOrderStatus(orderId: number, cartId: any) {
    return this.mutate(async () => {
      try {
        // Add current store code to header
        this.dataSources.graphQl.customHeaders = {
          ...this.dataSources.graphQl.customHeaders,
          Store: currentStoreView().storeCode || 'default',
        };

        const status = await this.dataSources.graphQl.queue({
          ...checkOrderStatus,
          params: { orderId, cartId },
        });

        if (status) {
          this.orderStatus = status.orderStatus;
        }
      } catch (e) {
        console.error('Zento Payment Methods: ', e.message);
        this.loading = false;
      }
    });
  }

  /**
   * Determines payments methods
   */
  public async zentoPaymentMethods(company: boolean, cart_id: string) {
    this.loading = true;

    return this.mutate(async () => {
      try {
        // Add current store code to header
        this.dataSources.graphQl.customHeaders = {
          ...this.dataSources.graphQl.customHeaders,
          Store: currentStoreView().storeCode || 'default',
        };

        const resp = await this.dataSources.graphQl.queue({
          ...zentoPaymentMethods,
          params: { input: { company, cart_id } },
        });

        if (resp) {
          this.availablePayments = resp.zentoAvailablePaymentMethods;

          this.loading = false;
        }
      } catch (e) {
        console.error('Zento Payment Methods: ', e.message);
        this.loading = false;
      }
    });
  }

  public get paymentMethods() {
    return [
      'zentomobilpay',
      'zentoeuplatesc',
      'zentoplationline',
      'adyen_cc',
      'adyen_oneclik',
      'tbipayment',
      'btrl_ipay',
    ]; // Add more when needed
  }

  /**
   * Determines checkout order data
   */
  public get order() {
    return this.lastOrder;
  }

  /**
   * Set checkout order data
   */
  public set order(order: any) {
    this.lastOrder = order;
  }

  /**
   * Payment data getter
   */
  public get payment() {
    return this.paymentData || {};
  }

  /**
   * Determines payment status
   */
  public get status() {
    return this.orderStatus || {};
  }

  /**
   * Determines available payment methods
   */
  public get availablePaymentsData() {
    return this.availablePayments || [];
  }

  /**
   * Set payment methods
   */
  public set availablePaymentsData(value) {
    this.availablePayments = value;
  }

  /**
   * Determines generate invoice checkbox value
   */
  public get generateInvoice() {
    return this.generateInvoiceValue;
  }

  /**
   * Set generate invoice checkbox value
   */
  public set generateInvoice(value: boolean) {
    this.generateInvoiceValue = value;
  }

  /**
   * Determines loading for available payment methods
   */
  public get loadingPayments() {
    return this.loading;
  }

  public async getFromStorage() {
    if (!this.context.isServer) {
      if (
        await this.mutate(async () => {
          // Fetch previous data from storage
          const res = await this.storage.getItem(LastOrderStorageKey);

          // Overwrite with storage data (if available)
          if (res && res.value) {
            this.lastOrder = res.value;
          }
        })
      ) {
        return this.lastOrder;
      }
    }

    return null;
  }

  public async setInStorage(order: any) {
    this.lastOrder = { ...order };

    return await this.mutate(async () => {
      this.storage.setItem(LastOrderStorageKey, {
        code: LastOrderStorageKey,
        created_at: new Date(),
        value: { ...order },
      });
    });
  }

  public async clearStorage() {
    return await this.storage.clear();
  }

  /**
   * Grab a UniversalStorage reference at registration time
   */
  protected beforeRegistration() {
    const storeView = currentStoreView();
    const dbNamePrefix = storeView.storeCode ? storeView.storeCode + '-' : '';
    const config = this.context.config;

    this.storage = new UniversalStorage(
      localForage.createInstance({
        name: (config.storeViews.commonCache ? '' : dbNamePrefix) + 'shop',
        storeName: 'payment',
        driver: localForage[config.localForage.defaultDrivers.claims],
      }),
    );
  }
}
